import { useEffect } from 'react';

const useClarity = (clarityProjectId: string): void => {
  useEffect(() => {
    (function(c: any, l: Document, a: string, r: string, i: string) {
        c[a] = c[a] || function(...args: any[]) {(c[a].q = c[a].q || []).push(args)};
        const t = l.createElement(r) as HTMLScriptElement;

        t.src = `https://www.clarity.ms/tag/${i}`; // Using template literals
        const y = l.getElementsByTagName(r)[0] as HTMLScriptElement | null;
        if(y && y.parentNode) {
            y.parentNode.insertBefore(t, y);
        }
    })(window, document, "clarity", "script", clarityProjectId);
  }, [clarityProjectId]);
};

export default useClarity;