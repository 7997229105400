import { add, subDays } from 'date-fns';
import { IOrder } from 'src/types/order';
//
import { _mock } from './_mock';
import { _addressBooks } from './_others';

// ----------------------------------------------------------------------

export const INVOICE_STATUS_OPTIONS = [
  { value: 'paid', label: 'Paid' },
  { value: 'pending', label: 'Pending' },
  { value: 'overdue', label: 'Overdue' },
  { value: 'draft', label: 'Draft' },
];

export const INVOICE_SERVICE_OPTIONS = [...Array(8)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.role(index),
  price: _mock.number.price(index),
}));

const ITEMS = [...Array(3)].map((__, index) => {
  const total = INVOICE_SERVICE_OPTIONS[index].price * _mock.number.nativeS(index);

  return {
    id: _mock.id(index),
    total,
    title: _mock.productName(index),
    description: _mock.sentence(index),
    price: INVOICE_SERVICE_OPTIONS[index].price,
    service: INVOICE_SERVICE_OPTIONS[index].name,
    quantity: _mock.number.nativeS(index),
  };
});

export const _invoices = [...Array(20)].map((_, index) => {
  const totalTax = _mock.number.price(index + 1);

  const totalDiscount = _mock.number.price(index + 2);

  const totalShipping = _mock.number.price(index + 3);

  const totalAmountTax = ITEMS.reduce((accumulator, item) => accumulator + item.price * item.quantity, 0);

  const totalAmount = totalAmountTax - totalShipping - totalDiscount + totalTax;
  const paymentStatus =
    (index % 2 && 'paid') || (index % 3 && 'pending') || (index % 4 && 'failed') || 'refunded';
  const status =
    (index % 2 && 'active') || (index % 3 && 'cancelled') || (index % 4 && 'active') || 'active';
  const salesChannel =
    (index % 2 && 'Shopify') || (index % 3 && 'Shopify Wholesale') || (index % 4 && 'Amazon') || 'Shopify';


  return {
    createDate: subDays(new Date(), index),
    dueDate: new Date(),
    id: _mock.id(index),
    orderNumber: `ORDER-199${index}`,
    paymentStatus,
    status,
    salesChannel,
    totalTax,
    totalShipping,
    totalAmount,
    totalDiscount,
    items: [
      {
        id: "item-1",
        title: "Sample Item 1",
        price: 100,
        total: 200,
        service: "Service A",
        quantity: 2,
        description: "This is a description for Sample Item 1"
      },
      {
        id: "item-2",
        title: "Sample Item 2",
        price: 50,
        total: 150,
        service: "Service B",
        quantity: 3,
        description: "This is a description for Sample Item 2"
      }
    ],
    orderTo: {
      name: "John Doe",
      fullAddress: "123 Main St, Springfield, IL 12345",
      company: "Doe Corp.",
      primary: true,
      phoneNumber: "555-123-4567",
      addressType: "Shipping"
    },
    orderFrom: {
      name: "John Doe",
      fullAddress: "123 Main St, Springfield, IL 12345",
      company: "Doe Corp.",
      primary: true,
      phoneNumber: "555-123-4567",
      addressType: "Shipping"
    },
    
  }
});
