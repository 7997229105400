import { add, subDays } from 'date-fns';
import { _mock } from './_mock';
import { _addressBooks } from './_others';
import { _productNames } from './assets';
// ----------------------------------------------------------------------

export const PRODUCT_GENDER_OPTIONS = [
  { label: 'Men', value: 'Men' },
  { label: 'Women', value: 'Women' },
  { label: 'Kids', value: 'Kids' },
];

export const PRODUCT_CATEGORY_OPTIONS = ['Shoes', 'Apparel', 'Accessories'];

export const PRODUCT_RATING_OPTIONS = ['up4Star', 'up3Star', 'up2Star', 'up1Star'];

export const PRODUCT_COLOR_OPTIONS = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107',
];

export const PRODUCT_COLOR_NAME_OPTIONS = [
  { value: 'red', label: 'Red' },
  { value: 'blue', label: 'Blue' },
  { value: 'cyan', label: 'Cyan' },
  { value: 'green', label: 'Green' },
  { value: 'yellow', label: 'Yellow' },
  { value: 'violet', label: 'Violet' },
  { value: 'black', label: 'Black' },
  { value: 'white', label: 'White' },
];

export const PRODUCT_SIZE_OPTIONS = [
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '8.5', label: '8.5' },
  { value: '9', label: '9' },
  { value: '9.5', label: '9.5' },
  { value: '10', label: '10' },
  { value: '10.5', label: '10.5' },
  { value: '11', label: '11' },
  { value: '11.5', label: '11.5' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
];

export const PRODUCT_STOCK_OPTIONS = [
];

export const PRODUCT_PUBLISH_OPTIONS = [
  {
    value: 'published',
    label: 'Published',
  },
  {
    value: 'draft',
    label: 'Draft',
  },
];

export const PRODUCT_SORT_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High - Low' },
  { value: 'priceAsc', label: 'Price: Low - High' },
];

export const PRODUCT_CATEGORY_GROUP_OPTIONS = [
  {
    group: 'Clothing',
    classify: ['Shirts', 'T-shirts', 'Jeans', 'Leather', 'Accessories'],
  },
  {
    group: 'Tailored',
    classify: ['Suits', 'Blazers', 'Trousers', 'Waistcoats', 'Apparel'],
  },
  {
    group: 'Accessories',
    classify: ['Shoes', 'Backpacks and bags', 'Bracelets', 'Face masks'],
  },
];

export const PRODUCT_CHECKOUT_STEPS = ['Cart', 'Billing & address', 'Payment'];

const productData = [
  { SKU: 'SB1001', Name: 'Snowy Peaks Snowboard', Type: 'Snowboards', Status: 'Good Standing', Available: 50, Quantity: 70, MonthSales: 8500, MonthlyOrders: 25, Price: 340, ProductHealth: 'A', DaysLeft: 45 },
  { SKU: 'SB1002', Name: 'Frostbite Freestyle', Type: 'Snowboards', Status: 'Good Standing', Available: 30, Quantity: 40, MonthSales: 6200, MonthlyOrders: 20, Price: 310, ProductHealth: 'B', DaysLeft: 38 },
  { SKU: 'JK2001', Name: 'Arctic Explorer Jacket', Type: 'Jackets', Status: 'Good Standing', Available: 75, Quantity: 90, MonthSales: 12750, MonthlyOrders: 45, Price: 283, ProductHealth: 'A', DaysLeft: 60 },
  { SKU: 'SB1003', Name: 'Powder Prowler', Type: 'Snowboards', Status: 'Replenish Now', Available: 0, Quantity: 25, MonthSales: 0, MonthlyOrders: 0, Price: 450, ProductHealth: 'C', DaysLeft: 0 },
  { SKU: 'GLO1001', Name: 'Icebreaker Gloves', Type: 'Accessories', Status: 'Good Standing', Available: 100, Quantity: 120, MonthSales: 3000, MonthlyOrders: 60, Price: 50, ProductHealth: 'B', DaysLeft: 90 },
  { SKU: 'SB1004', Name: 'Mountain Maverick', Type: 'Snowboards', Status: 'Good Standing', Available: 20, Quantity: 35, MonthSales: 4300, MonthlyOrders: 15, Price: 287, ProductHealth: 'B', DaysLeft: 30 },
  { SKU: 'JK2002', Name: 'Avalanche Armor Jacket', Type: 'Jackets', Status: 'Good Standing', Available: 40, Quantity: 55, MonthSales: 8100, MonthlyOrders: 35, Price: 231, ProductHealth: 'A', DaysLeft: 55 },
  { SKU: 'SB1005', Name: 'Backcountry Blitzer', Type: 'Snowboards', Status: 'Replenish Soon', Available: 10, Quantity: 40, MonthSales: 2100, MonthlyOrders: 7, Price: 300, ProductHealth: 'C', DaysLeft: 18 },
  { SKU: 'GLO1002', Name: 'Summit Seeker Gloves', Type: 'Accessories', Status: 'Good Standing', Available: 60, Quantity: 65, MonthSales: 1800, MonthlyOrders: 30, Price: 60, ProductHealth: 'A', DaysLeft: 75 },
  { SKU: 'SB1006', Name: 'Glacier Glider', Type: 'Snowboards', Status: 'Replenish Soon', Available: 15, Quantity: 55, MonthSales: 3500, MonthlyOrders: 10, Price: 350, ProductHealth: 'B', DaysLeft: 25 }
];

// ----------------------------------------------------------------------

export const _products = productData.map((product, index) => {

  // const sku = `16H9UR${index}`;

  // const name = _mock.productName(index);

  // const type = 
  // (index % 2 && 'Shoes') || (index % 3 && 'Apparel') || (index % 4 && 'Accessories') || 'Poop';

  // const price = _mock.number.price(index);

  // const coverUrl = _mock.image.product(index);

  const warehouse = 
  (index % 2 && 'Shoes') || (index % 3 && 'Apparel') || (index % 4 && 'Accessories') || 'Poop';

  // const quantity = _mock.number.rating(index + 1) * 20;

  // const monthlySales = _mock.number.price(index) * 100; 

  // const monthlyOrders = _mock.number.age(index)

  // const daysLeft = _mock.number.age(index) - 10;

  // const productScore = 
  // (index % 2 && 'Medium') || (index % 3 && 'Low') || (index % 4 && 'Low') || 'High';

  // const available = _mock.number.age(index + 1)

  const inventoryType = 
  (index % 2 && 'in stock') || (index % 3 && 'low stock') || (index % 4 && 'high') || 'high';

  // const publish = 'Published'

  // const category = 
  // (index % 2 && 'Shoes') || (index % 3 && 'Apparel') || (index % 4 && 'Accessories') || 'Poop';
  
  

  // const status =
  //   (index % 1 && 'Replenish Now') || (index % 2 && 'Good Standing') || (index % 4 && 'Out of Stock') || 'Replenish Soon';
  // // const status = 'Active';

  return {
    id: _mock.id(index),
    sku: product.SKU,
    name: product.Name,
    price: product.Price, // Assuming MonthSales is the price
    type: product.Type,
    status: product.Status,
    warehouse, // Adjust as needed
    quantity: product.Quantity,
    monthlySales: product.MonthSales,
    monthlyOrders: product.MonthlyOrders,
    daysLeft: product.DaysLeft,
    productScore: product.ProductHealth, // Assuming ProductHealth is the product score
    available: product.Available, // Adjust as needed
    inventoryType,
    publish: 'Published', // Adjust as needed
    coverUrl: '', // Placeholder, adjust as needed
    category: product.Type,
    createdAt: subDays(new Date(), index), // Placeholder, adjust as needed
  };
});

// export const _products = [...Array(20)].map((_, index) => {
  
//   const sku = `16H9UR${index}`;

//   const name = _mock.productName(index);

//   const type = 
//   (index % 2 && 'Shoes') || (index % 3 && 'Apparel') || (index % 4 && 'Accessories') || 'Poop';

//   const price = _mock.number.price(index);

//   const coverUrl = _mock.image.product(index);

//   const warehouse = 
//   (index % 2 && 'Shoes') || (index % 3 && 'Apparel') || (index % 4 && 'Accessories') || 'Poop';

//   const quantity = _mock.number.rating(index + 1) * 20;

//   const monthlySales = _mock.number.price(index) * 100; 

//   const monthlyOrders = _mock.number.age(index)

//   const daysLeft = _mock.number.age(index) - 10;

//   const productScore = 
//   (index % 2 && 'Medium') || (index % 3 && 'Low') || (index % 4 && 'Low') || 'High';

//   const available = _mock.number.age(index + 1)

//   const inventoryType = 
//   (index % 2 && 'in stock') || (index % 3 && 'low stock') || (index % 4 && 'high') || 'high';

//   const publish = 'Published'

//   const category = 
//   (index % 2 && 'Shoes') || (index % 3 && 'Apparel') || (index % 4 && 'Accessories') || 'Poop';
  
  

//   const status =
//     (index % 1 && 'Replenish Now') || (index % 2 && 'Good Standing') || (index % 4 && 'Out of Stock') || 'Replenish Soon';
//   // const status = 'Active';


//   return {
//     id: _mock.id(index),
//     sku,
//     name,
//     price, 
//     type, 
//     status,
//     warehouse,
//     quantity,
//     monthlySales,
//     monthlyOrders,
//     daysLeft,
//     productScore,
//     available,
//     inventoryType,
//     publish,
//     coverUrl,
//     category,
//     createdAt: subDays(new Date(), index),
//   }
// });
